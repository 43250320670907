import { useState, useEffect } from "react";
import { Button, Card, Container, Form, ProgressBar, ListGroup, ListGroupItem } from "react-bootstrap";
import "./App.css"
import axios from "axios";
// import { ReactComponent as AppStoreLogo } from "./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, getDoc, updateDoc, setDoc, increment, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import FrontPage from "./pages/FrontPage";
import Welcome from "./pages/Welcome";
import Countdown from "./pages/Countdown";
// import GoogleAds from "./components/googleads";
import BarChart from "./components/BarChart";
import SocialShare from "./components/SocialShare";
import AppStoreLogo from "./components/AppStoreLogo";
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en' // locale-data for en

function App() {

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LdbuAYfAAAAAN-PYlG1Z4l53U1zmNR9D9T9-SA5"),
    isTokenAutoRefreshEnabled: true
  });

  const [currentPage, setCurrentPage] = useState("welcome");
  const [score, setScore] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [ifHighScore, setIfHighScore] = useState(false);
  const [selectedGame, setSelectedGame] = useState(1);

  const setPage = (page) => setCurrentPage(page);
  const setGame = (game) => setSelectedGame(game);

  const Game = () => {

    const [gameClock, setGameClock] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState();
    const [numberOfDigits, setNumberOfDigits] = useState();
    const [problemLabel, setProblemLabel] = useState("");
    const [answerLabel, setAnswerLabel] = useState("");
    const [blinkGreen, setBlinkGreen] = useState(false);
    const [blinkPink, setBlinkPink] = useState(false);
    const [correctCount, setCorrectCount] = useState(0);

    const getRndInteger = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const getRndIntegerExMax = (min, max) => {
      return Math.floor(Math.random() * (max - min)) + min;
    }

    const generateProblem = () => {
      var n1 = 0;
      var n2 = 0;
      setCorrectAnswer();
      setNumberOfDigits();
      setAnswerLabel("");
      setBlinkGreen(false);
      setBlinkPink(false);
      var sign = getRndInteger(0, 3);
      if (sign === 0) {
        const random = getRndInteger(10, 89);
        n1 = random === n1 ? getRndInteger(10, 89) : random
        n2 = getRndInteger(10, 99 - n1);
        setCorrectAnswer(n1 + n2);
        setNumberOfDigits(String(n1 + n2).length);
        setProblemLabel(String(n1 + " + " + n2));
      } else if (sign === 1) {
        const random = getRndInteger(12, 99);
        n1 = random === n1 ? getRndInteger(12, 99) : random
        n2 = getRndIntegerExMax(10, n1);
        setCorrectAnswer(n1 - n2);
        setNumberOfDigits(String(n1 - n2).length);
        setProblemLabel(String(n1 + " - " + n2));
      } else if (sign === 2) {
        const random1 = getRndInteger(2, 9);
        n1 = random1 === n1 ? getRndInteger(2, 9) : random1
        const random2 = getRndInteger(2, 10);
        n2 = random2 === n2 ? getRndInteger(2, 10) : random2
        setCorrectAnswer(n1 * n2);
        setNumberOfDigits(String(n1 * n2).length);
        setProblemLabel(String(n1 + " x " + n2));
      } else if (sign === 3) {
        const random1 = getRndInteger(2, 9);
        const random2 = getRndInteger(2, 10);
        n1 = random1 * random2;
        n2 = random1;
        setCorrectAnswer(random2);
        setNumberOfDigits(String(random2).length);
        setProblemLabel(String(n1 + " ÷ " + n2));
      }
    }

    const checkAnswer = (event) => {
      const userAnswer = event.target.value;
      setAnswerLabel(userAnswer);
      if (numberOfDigits === String(userAnswer).length) {
        if (Number(userAnswer) === correctAnswer) {
          setCorrectCount(prevScore => prevScore + 1);
          setBlinkGreen(true);
          if (selectedGame === 2) {
            setGameClock(prevGameClock => prevGameClock - 0.5);
          }
        } else {
          setBlinkPink(true);
          if (selectedGame === 2) {
            setGameClock(30);
            return
          }
        }
        setTimeout(() => generateProblem(), 200);
      }
    }

    const saveScore = async () => {  
      if (correctCount > 0) {
        const scoreRef = doc(db, selectedGame === 1 ? "scores" : "scores2", String(correctCount));
        const scoreSnap = await getDoc(scoreRef).catch((error) => console.log(error));
        if (scoreSnap.exists()) {
          await updateDoc(scoreRef, { count: increment(1) }).catch((error) => console.log(error));
        } else {
          await setDoc(scoreRef, { count: 1, score: correctCount }).catch((error) => console.log(error));
        }
      }

      const res = await axios.get("https://geolocation-db.com/json/2d7a1090-a7e0-11ec-bb96-d99740183a81");
      const countryCode = res.data.country_code;
      const countryRef = doc(db, selectedGame === 1 ? "countries" : "countries2", String(countryCode));
      const countrySnap = await getDoc(countryRef).catch((error) => console.log(error));

      if (countrySnap.exists()) {
        const highScore = countrySnap.data()["highScore"];
        if (correctCount > highScore) {
          await updateDoc(countryRef, { highScore: correctCount })
            .catch((error) => console.log(error));
          setIfHighScore(true);
        }
      } else {
        countryCode !== null && await setDoc(countryRef, { highScore: correctCount }).catch((error) => console.log(error));
      }

      setScore(correctCount);
      setCountryCode(countryCode);
      setCurrentPage("results");
    }

    useEffect(() => {
      gameClock === 0 && generateProblem()
      const intervalId = setInterval(() => setGameClock(prevGameClock => prevGameClock + 1), 1000);
      if (gameClock > 30) {
        saveScore();
      }
      return () => clearInterval(intervalId);
    }, [gameClock]);

    return (
      <Form noValidate>
        <Form.Group className="mt-2 mb-2" controlId="formBasicEmail">
          <ProgressBar min={0} max={30} now={gameClock} className="mb-5" />
          <Form.Label><h1 className="display-4 mb-5">{problemLabel}</h1></Form.Label>
          <Form.Control type="text" pattern="\d*" value={answerLabel} onChange={(event) => checkAnswer(event)} isValid={blinkGreen} isInvalid={blinkPink} />
        </Form.Group>
      </Form>
    )
  }

  const Results = () => {

    const [rankings, setRankings] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartOne, setChartOne] = useState([]);
    const [chartTwo, setChartTwo] = useState([]);
    const [playCount, setPlayCount] = useState(0);
    const [title, setTitle] = useState("");

    let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryName = countryCode === null ? "Unknown" : regionNames.of(String(countryCode));

    const loadRankings = async () => {
      const countriesRef = collection(db, selectedGame === 1 ? "countries" : "countries2");
      const countriesQuery = query(countriesRef, orderBy("highScore", "desc"), limit(25));
      const countriesSnap = await getDocs(countriesQuery).catch((error) => console.log(error));
      var count = 0;
      var countriesList = [];
      countriesSnap.forEach((country) => {
        let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
        const countryName = regionNames.of(String(country.id));
        const highScore = country.data()["highScore"];
        const rank = countriesList.length === 0 ? "1. " : countriesList[count - 1]["highScore"] === highScore ? "-. " : String(count + 1) + ". ";
        countriesList.push({
          countryName: rank + countryName,
          highScore: highScore
        });
        count++;
      });
      setRankings(countriesList);
    }

    const Country = (country) => {
      return (
        <CountryItem
          key={country.countryName}
          countryName={country.countryName}
          highScore={country.highScore}
        />
      )
    }

    const CountryItem = (props) => {
      return (
        <ListGroupItem>{props.countryName + ": " + props.highScore}</ListGroupItem>
      )
    }

    const loadCharts = async () => {
      const scoresRef = collection(db, selectedGame === 1 ? "scores" : "scores2");
      const scoresQuery = query(scoresRef, orderBy("score", "asc"));
      const scoresSnap = await getDocs(scoresQuery).catch((error) => console.log(error));

      var chartLabelsData = [];
      var chartOneData = [];
      var chartTwoData = [];
      var playCountData = 0;

      scoresSnap.forEach((scoreDoc) => {
        const score = scoreDoc.data()["score"];
        const count = scoreDoc.data()["count"];
        playCountData = playCountData + count;
        chartLabelsData.push(score);
        chartOneData.push(count)
        chartTwoData.push(playCountData);
      });

      setPlayCount(playCountData);
      setChartLabels(chartLabelsData);
      setChartOne(chartOneData.map(count => Math.round(count / playCountData * 10000) / 100));
      setChartTwo(chartTwoData.map(count => Math.round(count / playCountData * 10000) / 100));
    }

    useEffect(() => {
      loadRankings();
      loadCharts();
      setTitle("I scored a " + score + " on Snap Math Challenge!");
    }, []);

    return (
      <div className="mt-2 mb-2">
        <h4 className="mb-3">Your score: {String(score)}</h4>
        {ifHighScore ? <p className="mb-3" style={{ color: "red" }}>New {countryName} high score!</p> : <p className="mb-3">Your region: {countryName}</p>}
        <SocialShare title={title} />
        {/* <div id="ad1" className="mb-3"><GoogleAds slot="5106022250" /></div> */}
        <ListGroup className="mb-3">{rankings.map(Country)}</ListGroup>
        <p className="mb-3"><strong>Play Count: {playCount}</strong></p>
        {/* <div id="ad2" className="mb-3"><GoogleAds slot="5106022250" /></div> */}
        <BarChart
          label="Percent of Scores"
          x={chartLabels}
          y={chartOne}
        />
        <BarChart
          label="Percentile of Scores"
          x={chartLabels}
          y={chartTwo}
        />
        <div className="mt-3"><Button onClick={() => window.location.reload()}>Play Again</Button></div>
      </div>
    )
  }

  return (
    <Container fluid>
      <Card style={{ width: '20rem' }} className="mx-auto text-center mt-4">
        <Card.Body>
          {currentPage === "welcome" && <Welcome onSetPage={setPage} onSetGame={setGame} />}
          {currentPage === "countdown" && <Countdown onSetPage={setPage} />}
          {currentPage === "game" && <Game />}
          {currentPage === "results" && <Results />}
        </Card.Body>
      </Card>
      <div className="mx-auto text-center mt-3">
        <AppStoreLogo url="https://taptapnumber.page.link/app" />
      </div>
      {currentPage === "welcome" && <FrontPage />}
      <div className="mx-auto text-center mt-3">
        <p>
          <a className="text-muted" href="https://www.justinhwa.com" style={{ textDecoration: 'none' }}>
            © 2022 Justin Hwa & Associates, LLC
          </a>
        </p>
      </div>
    </Container>
  );
}

export default App;
