import React, { useState, useEffect } from "react";

const Countdown = (props) => {
    const [count, setCount] = useState(3);
    useEffect(() => {
        const intervalRef = setInterval(() => setCount(prevCount => prevCount - 1), 500);
        count < 0 && props.onSetPage("game");
        return () => clearInterval(intervalRef);
    }, [count]);
    return (
        <h1 className="display-1"><strong>Ready</strong></h1>
    );
}

export default Countdown;