import React from "react";
import { Button } from "react-bootstrap";

const Welcome = (props) => {

    const selectGame1 = () => {
        props.onSetPage("countdown");
        props.onSetGame(1);
    }

    const selectGame2 = () => {
        props.onSetPage("countdown");
        props.onSetGame(2);
    }

    return (
        <div className="mt-2 mb-2">
            <h1>Snap Math</h1>
            <h1 className="mb-5">Challenge</h1>
            <p className="mb-5">Test Your Brain</p>
            <div className="mb-3"><Button variant="primary" onClick={() => selectGame1()}>Time Trial</Button></div>
            <div><Button variant="primary" onClick={() => selectGame2()}>Hot Streak</Button></div>
        </div>
    );
}

export default Welcome;