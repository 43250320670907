import React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LineShareButton,
    LineIcon,
    WeiboShareButton,
    WeiboIcon
} from "react-share";

const SocialShare = (props) => {

    const title = props.title;
    const shareUrl = "https://www.snapmathchallenge.com";

    return (
        <div className="mb-3">
            <FacebookShareButton
                url={shareUrl}
                quote={title}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
                url={shareUrl}
                title={title}
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LineShareButton
                url={shareUrl}
                title={title}
            >
                <LineIcon size={32} round />
            </LineShareButton>
            <WeiboShareButton
                url={shareUrl}
                title={title}
            >
                <WeiboIcon size={32} round />
            </WeiboShareButton>
        </div>
    )
}

export default SocialShare;