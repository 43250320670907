import React from "react";
import { Card } from "react-bootstrap";
import AppStoreLogo from "../components/AppStoreLogo";
import challengelogo60 from "../images/challengelogo60.png";
import multiplylogo60 from "../images/multiplylogo60.png";

const FrontPage = () => {
    return (
        <Card style={{ width: '20rem' }} className="mx-auto mt-3">
            <Card.Title className="text-center mt-2">
                <div className="mt-3 mb-4"><img src={challengelogo60} alt="Snap Math Challenge Logo" /></div>
                About
            </Card.Title>
            <Card.Body>
                <p>Thanks for playing Snap Math Challenge. This app is part of a suite
                    of Snap Math apps that teaches and challenges quick math skills.</p>
                <p>For a more immersive experience, download the iOS version via
                    the link above. Coming soon to Android!</p>
            </Card.Body>
            <Card.Title className="text-center">
                Train with Us
            </Card.Title>
            <Card.Body>
                <p>Math isn't just a practical skill; calculating and recalling
                    information sharpens your mind.</p>
                <p>Snap Math apps are built for both students practicing the skill
                    and people of all ages looking to put their brains on the
                    treadmill.</p>
                <p>Check out more apps below.</p>
            </Card.Body>
            <Card.Title className="text-center">
                Multiply
                <div className="mt-4"><img src={multiplylogo60} alt="Snap Math Multiply Logo" /></div>
            </Card.Title>
            <Card.Body>
                <p>Snap Math Multiply takes multiplication table training to a
                    new level. No more flash cards and helpless memorization!</p>
                <p>Get results quicker by using your senses and rhythm to build
                    memory - the same way you learn songs and languages. Turn on the
                    voice feature to both hear and see each problem and answer in training.
                    Practice 3 times a day per number set and become an expert in two
                    weeks or less.</p>
                <div className="text-center"><AppStoreLogo url="https://snapmathmultiply.page.link/app" /></div>
            </Card.Body>
            <Card.Title className="text-center">
                Add / Subtract
            </Card.Title>
            <Card.Body className="text-center">
                <p>Coming soon</p>
            </Card.Body>
            <Card.Title className="text-center">
                Divide
            </Card.Title>
            <Card.Body className="text-center">
                <p>Coming soon</p>
            </Card.Body>
        </Card>
    )
}

export default FrontPage;