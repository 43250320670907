import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = (props) => {
    return (
        <Bar
            options={{
                responsive: true
            }}
            data={{
                labels: props.x,
                datasets: [{
                    label: props.label,
                    backgroundColor: "rgba(91, 192, 222)",
                    borderColor: "rgba(91, 192, 222)",
                    data: props.y
                }]
            }}
        />
    )
}

export default BarChart;