import React from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as AppStoreLogoSVG } from "../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

const AppStoreLogo = (props) => {
    return (
        <Button variant="link" href={props.url}>
            <AppStoreLogoSVG />
        </Button>
    );
}

export default AppStoreLogo;